/* ================================================================================
dialogs
================================================================================ */

.dialogs-sm {
    max-width: 500px !important;
    width: 100%;
    margin: 0 20px;
}

.dialogs-main {
    max-width: 700px !important;
    width: 100%;
    margin: 0 20px;
}

.dialogs-md {
    max-width: 900px !important;
    width: 100%;
    margin: 0 20px;
}

.dialogs-lg {
    max-width: 1200px !important;
}

.dialogs-lg {
    max-width: 1200px !important;
    width: 100%;
    margin: 0 20px;
}

.custom-dialog-container .mat-dialog-container {
    overflow-y: hidden;
    max-width: 1200px !important;
    width: 100%;
    margin: 0 20px;
}

.custom-dialog-container-scroll {
    overflow-y: visible !important;
    max-width: 1200px !important;
    width: 100%;
    margin: 0 20px;
}

/* ================================================================================
menu transition
================================================================================ */

.layout-menu {
    transition: 0.5s;
}

.alert-light-warning {
    background-color: #fff6e3;
}

/* ================================================================================
dashboard
================================================================================ */

.primary .bg-gradient {
    background-image: linear-gradient(144.16deg, rgba(115, 102, 255, 0.1) 19.06%, rgba(115, 102, 255, 0) 79.03%) !important;
}

.secondary .bg-gradient {
    background-image: linear-gradient(144.16deg, rgba(255, 51, 100, 0.1) 19.06%, rgba(255, 51, 100, 0) 79.03%) !important;
}

.success .bg-gradient {
    background-image: linear-gradient(144.16deg, rgba(84, 186, 74, 0.1) 19.06%, rgba(84, 186, 74, 0) 79.03%) !important;
}

.warning .bg-gradient {
    background-image: linear-gradient(144.16deg, rgba(255, 170, 5, 0.1) 19.06%, rgba(255, 170, 5, 0) 79.03%) !important;
}

.small-widget {
    overflow: hidden;
}

.small-widget h4 {
    margin-bottom: -3px;
}

.small-widget i {
    font-weight: 700;
    font-size: 11px;
}

.small-widget .card-body {
    padding: 15px;
}

.small-widget .bg-gradient {
    width: 66px;
    height: 67px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    right: -12px;
    top: 50%;
    position: absolute;
    transform: translateY(-50%);
}

[dir=rtl] .small-widget .bg-gradient {
    right: unset;
    left: -12px;
    transform: translateY(-50%) scaleX(-1);
}

@media (max-width: 1399px) {
    .small-widget .bg-gradient {
        width: 60px;
        height: 60px;
    }
}

.small-widget .bg-gradient svg {
    width: 25px;
    height: 25px;
}

@media (max-width: 1399px) {
    .small-widget .bg-gradient svg {
        width: 22px;
        height: 22px;
    }
}

.small-widget:hover {
    transform: translateY(-5px);
    transition: 0.5s;
}

.small-widget:hover .bg-gradient svg {
    animation: tada 1.5s ease infinite;
}

.appointment-table table tr td {
    position: relative;
    border-top: none;
    padding: 3px 0;
    vertical-align: middle;
}

.appointment-table table tr td:first-child {
    width: 50px;
}

.appointment-table table tr td .status-circle {
    top: 1px;
    left: 35px;
    border-width: 3px;
}

.appointment-table table tr td p,
.appointment-table table tr td .btn {
    font-weight: 500;
    font-size: 12px;
}

.appointment-table table tr td .btn {
    padding: 4px 12px;
}

.appointment-table table tr .img-content-box a {
    color: var(--body-font-color);
    transition: 0.5s;
    display: inline-block !important;
}

// .appointment-table table tr .img-content-box span {
//     display: block;
// }

.appointment-table table tr:hover .img-content-box a {
    color: var(--theme-deafult);
    transition: 0.5s;
}

.appointment-table table tr:first-child td {
    padding-top: 0 !important;
}

.appointment-table table tr:last-child td {
    padding-bottom: 0 !important;
}

.appointment-table.customer-table table tr td {
    padding: 10px 0;
}

.appointment-table.customer-table table tr td img {
    min-width: 40px;
}

* {
scrollbar-width: none;
scrollbar-color: #c4b0c9 #ffffff;
}

*::-webkit-scrollbar {
width: 10px;
}

*::-webkit-scrollbar-track {
background: #ffffff;
}

*::-webkit-scrollbar-thumb {
background-color: #c4b0c9;
border-radius: 10px;
border: 3px solid #ffffff;
}

.placeholder {
    background-color: white;
    cursor: pointer;
}

.cursor-pointer {
    cursor: pointer;
}